import React, { useRef, forwardRef, useEffect } from "react"
import cx from "classnames"
import { detect } from "detect-browser"

import styles from "./styles.module.scss"

const ImageTitle = ({ text, className, direction }, ref) => {
  const browser = detect()

  const wrapperRef = useRef(ref)
  const titleRef = useRef(null)

  const titleClasses = cx({
    [styles["image-title"]]: true,
    [styles["image-title--vertical"]]: direction == "vertical",
    [styles["image-title--horizontal"]]:
      direction == "horizontal" || !direction,
  })

  useEffect(() => {
    if (browser.name == "safari" && direction == "vertical") {
      wrapperRef.current.style.padding = "5rem 4rem 4rem 0"
    }
  }, [])

  if (!text) return null

  return (
    <div
      className={`image-title ${titleClasses} ${className || ""}`}
      ref={wrapperRef}
    >
      <h5
        ref={titleRef}
        className={`link link--small link--white ${styles["image-title__inner"]}`}
      >
        {text}
      </h5>
    </div>
  )
}

export default forwardRef(ImageTitle)

import React from 'react'
import { Link as GatsbyLink } from 'gatsby'

const Link = ({ children, to, className, activeStyle, onClick, anchor, ...other }) => {

    const defaultLanguage = 'en'
    const internal = to.includes('tel') || to.includes('http') || to.includes('@')

    if (internal) {
        let link = to.includes('@') ? `mailto:${to}` : to
        
        return (
            <a className='link' href={link} {...other}>{children}</a>
        )
    } else {

        let toLink
        let pagePath = to.split('/')[1]
        let activeLanguage = to.split('/')[0]

        if (activeLanguage == 'en') {
            toLink = `/${pagePath}`
        } else {
            toLink = `/${to}`
        }

        if (anchor) {
            toLink = `/${pagePath}#${anchor}`
        }

        return (
            <GatsbyLink
                to={toLink}
                className={className}
                onClick={onClick}
                activeStyle={activeStyle}
                {...other}
            >{children}</GatsbyLink>
        )
    }

}

export default Link
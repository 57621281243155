import React from 'react'

import DynamicIcon from './../DynamicIcon'

import styles from './styles.module.scss'

const Socials = ({ data, className }) => {
    return (
        <div className={`${styles['socials']} ${className || ''}`}>
            <span className={`link link--white ${styles['socials__title']}`}><b>Follow us</b></span>
            <ul className={styles['socials__list']}>
                {data && data.map((entry, i) => 
                    <li className={styles['socials__list-item']} key={i}>
                        <a className={styles['socials__item-link']} href={entry.link.url} target='_blank'>
                            <DynamicIcon className={styles['socials__icon']} type={entry.text.toLowerCase()} />
                        </a>
                    </li>
                )}
            </ul>
        </div>
    )
}

export default Socials

import React from 'react'

import SbEditable from 'storyblok-react'
import cx from 'classnames'

import Link from './../Link'
import DynamicIcon from '../DynamicIcon'

import styles from './styles.module.scss'

const ButtonInner = (text) => {
    return (
        <div className={styles['button-primary__inner']}>
            <div className={styles['button-primary__icon-wrapper']}>
                <DynamicIcon type='arrow' className={styles['button-primary__icon']} />
            </div>
            <span className={`link ${styles['button-primary__label']}`}>{text}</span>
            <div className={styles['button-primary__icon-wrapper']}>
                <DynamicIcon type='arrow' className={styles['button-primary__icon']} />
            </div>
        </div>
    )
}

const ButtonOuter = ({ blok }) => {
    
    const buttonClasses = cx({
        'button': true,
        [styles['button-primary']]: true,
        [styles[blok.type]]: true
    })
    
    if (!blok.link) return <p>Please add information to the button</p>

    if (blok.link.linktype == 'asset') {
        return (
            <a href={blok.link.cached_url} target='_blank' className={buttonClasses}>
                {ButtonInner(blok.text)}
            </a>
        )
    } else if (blok.link.linktype === 'url') {
        return (
            <a href={blok.link.cached_url} target='_blank' className={buttonClasses}>
                {ButtonInner(blok.text)}
            </a>
        )
    } else if (blok.link.linktype === 'email') {
        return (
            <a href={`mailto:${blok.link.email}`} className={buttonClasses}>
                {ButtonInner(blok.text)}
            </a>
        )
    } else {
        
        if (!blok.link.cached_url) {
            return <p>Add link to button</p>
        }

        return (
            <Link to={blok.link ? blok.link.cached_url : '/'} className={buttonClasses}>
                {ButtonInner(blok.text)}
            </Link>
        )
    }
}

const ButtonPrimary = (blok) => {
    return (
        <SbEditable content={blok} key={blok._uid}>
            <ButtonOuter blok={blok} />
        </SbEditable>
    )
}

export default ButtonPrimary
import React from 'react'

const Icon = ({ className }) => {
    return (
        <svg className={`icon ${className || ''}`} xmlns='http://www.w3.org/2000/svg' width='31' height='22' viewBox='0 0 31 22'>
            <path className='icon__path' d='M20.88.33a1.1,1.1,0,0,0-1.57,0,1.12,1.12,0,0,0,0,1.56l7.92,8H1.09a1.11,1.11,0,0,0,0,2.22H27.23l-7.92,8a1.15,1.15,0,0,0,0,1.58,1.1,1.1,0,0,0,1.57,0l9.79-9.89a1.09,1.09,0,0,0,0-1.56Z'/>
        </svg>
    )
}

export default Icon
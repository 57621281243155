import React from "react"
import { Link } from "gatsby"
import SbEditable from "storyblok-react"
import { CopyToClipboard } from "react-copy-to-clipboard"

import LinkSVG from "./../../images/link.svg"

import { getUrl } from "./../../utils/helpers"

import styles from "./styles.module.scss"

const Thumbnail = ({ blok, className }) => {
  let data = JSON.parse(blok.content)

  const getBlogLink = slug => {
    let activeLang = slug.split("/")[0]
    return `${activeLang == "en" ? "/" : `/${activeLang}/`}news/${
      slug.split("/")[2]
    }`
  }

  return (
    <SbEditable content={blok} key={blok._uid}>
      <div className={styles["thumbnail"]}>
        <Link
          className={`button ${styles["thumbnail__link"]}`}
          to={getBlogLink(blok.full_slug)}
        >
          <figure className={styles["thumbnail__image-wrapper"]}>
            {data?.image?.filename && (
              <img
                className={styles["thumbnail__image"]}
                src={data.image.filename}
                alt={data.image.alt}
              />
            )}
          </figure>
          <div className={styles["thumbnail__content"]}>
            <div className={styles["thumbnail__header"]}>
              <span className={`category ${styles["thumbnail__category"]}`}>
                {data.category}
              </span>
              <h3 className={`heading-4 ${styles["thumbnail__title"]}`}>
                <b>{blok.name}</b>
              </h3>
            </div>
            <div className={styles["thumbnail__footer"]}>
              <span className={`link ${styles["thumbnail__footer-label"]}`}>
                {blok.published_at}
              </span>
              {/* <CopyToClipboard text={getBlogPostLink()}>
                                <button className={`button`}>
                                    <LinkSVG className={styles['thumbnail__footer-icon']}/><span className={`link ${styles['thumbnail__footer-label']}`}>Copy link</span>
                                </button>
                            </CopyToClipboard> */}
            </div>
          </div>
        </Link>
      </div>
    </SbEditable>
  )
}

export default Thumbnail

import React, { useRef, useState, useEffect, useContext } from "react"
import Select from "react-select"

import LangContext from "./../../contexts/LangContext"

import styles from "./styles.module.scss"

const LanguageSwitch = ({ className }) => {
  const { switchLang, lang } = useContext(LangContext)

  const languageOptions = [
    {
      value: "en",
      label: "🇬🇧  English",
    },
    {
      value: "de",
      label: "🇩🇪  Deutsch",
    },
    {
      value: "nl",
      label: "🇳🇱  Nederlands",
    },
    {
      value: "ru",
      label: "🇷🇺  Pусский",
    },
  ]

  const languageLabels = {
    en: "🇬🇧  English",
    de: "🇩🇪  Deutsch",
    nl: "🇳🇱  Nederlands",
    ru: "🇷🇺  Pусский",
  }

  const customStyles = {
    placeholder: provided => ({
      ...provided,
      fontFamily: "Circular Book",
      fontSize: "1.4rem",
      lineHeight: "2.2rem",
      color: "var(--color-dark)",
    }),
    option: (provided, state) => ({
      ...provided,
      fontFamily: "Circular Book",
      fontSize: "1.4rem",
      padding: "1.5rem",
      backgroundColor: state.isFocused ? "#eaeaea" : "transparent",
      color: state.isFocused ? "var(--color-dark)" : "var(--color-grey-cold)",
      "&:active": {
        backgroundColor: "transparent",
        color: "var(--color-dark)",
      },
    }),
    input: provided => ({
      ...provided,
      fontFamily: "Circular Book",
      fontSize: "1.4rem",
    }),
    valueContainer: provided => ({
      ...provided,
      width: "10rem",
      padding: "1.5rem 2rem",
    }),
    singleValue: provided => ({
      ...provided,
      fontFamily: "Circular Book",
      fontSize: "1.4rem",
      lineHeight: "2.2rem",
    }),
    control: (provided, state) => ({
      ...provided,
      boxShadow: state.isFocused ? 0 : 0,
      borderColor: "transparent",
      "&:hover": {
        borderColor: "transparent",
      },
    }),
    menuList: () => ({
      padding: 0,
    }),
    indicatorSeparator: () => ({
      display: "none",
      padding: "1.5rem",
    }),
    dropdownIndicator: () => ({
      padding: "1.5rem",
      color: "var(--color-dark)",
    }),
    noOptionsMessage: () => ({
      textAlign: "left",
      fontFamily: "Circular Book",
      fontSize: "1.2rem",
      padding: "1.5rem",
    }),
  }

  return (
    <div className={className}>
      <Select
        className={styles["language-switch"]}
        styles={customStyles}
        options={languageOptions}
        placeholder={languageLabels[lang]}
        menuPlacement="top"
        isSearchable={false}
        onChange={e => {
          switchLang(e.value)
        }}
      />
    </div>
  )
}

export default LanguageSwitch

import React, { useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { CopyToClipboard } from "react-copy-to-clipboard"
import SbEditable from "storyblok-react"
import cx from "classnames"

import TextRenderer from "./../TextRenderer"
import ImageTitle from "./../ImageTitle"
import ResponsiveImage from "./../ResponsiveImage"
import Thumbnail from "./../Thumbnail"

import AuthorSVG from "./../../images/author.svg"
import DateSVG from "./../../images/date.svg"
import LinkSVG from "./../../images/link.svg"

import FacebookSVG from "./../../images/share-facebook.svg"
import TwitterSVG from "./../../images/share-twitter.svg"
import LinkedinSVG from "./../../images/share-linkedin.svg"
import WhatsappSVG from "./../../images/share-whatsapp.svg"

import { getBlogPostLink } from "./../../utils/helpers"

import styles from "./styles.module.scss"

const ShareLinks = ({ url, title }) => {
  const facebookShareHandler = url => {
    if (typeof window == "undefined") return

    const shareUrl = `https://www.facebook.com/sharer.php?u=${url}`
    const size = 570
    const left = (window.innerWidth - size) / 2
    const top = (window.innerHeight - size) / 2
    const params = `menubar=no, toolbar=no, status=no, width=${size}, height=${size}, top=${top}, left=${left}`

    window.open(shareUrl, "NewWindow", params)
  }

  return (
    <aside className={styles["share"]}>
      <ul className={styles["share__list"]}>
        <li className={styles["share__list-item"]}>
          <a
            href={`whatsapp://send?text=${title} Read more: ${url}.`}
            target="_blank"
            className={`button ${styles["share__button"]}`}
          >
            <WhatsappSVG className={styles["share__icon"]} />
          </a>
        </li>
        <li className={styles["share__list-item"]}>
          <a
            href={` http://www.linkedin.com/shareArticle?mini=true&url=${url}&title=${title} Read more: ${url}.`}
            target="_blank"
            className={`button ${styles["share__button"]}`}
          >
            <LinkedinSVG className={styles["share__icon"]} />
          </a>
        </li>
        <li className={styles["share__list-item"]}>
          <a
            href={`https://twitter.com/intent/tweet/?text=${title} Read more: ${url}.`}
            target="_blank"
            className={`button ${styles["share__button"]}`}
          >
            <TwitterSVG className={styles["share__icon"]} />
          </a>
        </li>
        <li className={styles["share__list-item"]}>
          <button
            onClick={() => facebookShareHandler(url)}
            target="_blank"
            className={`button ${styles["share__button"]}`}
          >
            <FacebookSVG className={styles["share__icon"]} />
          </button>
        </li>
      </ul>
    </aside>
  )
}

const PostArticle = ({ blok: blok, slug, title, date }) => {
  const [copied, setCopied] = useState(false)

  const { allPosts } = useStaticQuery(graphql`
    query {
      allPosts: allStoryblokEntry(
        filter: { field_component: { in: ["blogpost"] } }
      ) {
        nodes {
          uuid
          slug
          name
          full_slug
          content
          field_component
          published_at(formatString: "MMMM DD, YYYY", locale: "EN")
        }
      }
    }
  `)

  const copyClasses = cx({
    "link link--large light": true,
    [styles["post__info-label"]]: true,
  })

  return (
    <SbEditable content={blok}>
      <div className={styles["post"]}>
        <div className="container">
          <div className={`row row--center ${styles["post__title-row"]}`}>
            <div className="col col-12 text-center col-md-7">
              <h6 className="heading heading-5 ">
                <span className="subtitle">{blok.category}</span>
              </h6>
              <h1 className="heading heading-4 ">
                <b>{title}</b>
              </h1>
            </div>
          </div>
          <div className={`row row--center ${styles["post__image-row"]}`}>
            <div className="col col-12 text-center col-md-9 p-0">
              <div className={styles["post__image-wrapper"]}>
                <ResponsiveImage
                  className={styles["post__image"]}
                  image={blok.image}
                  desktop={2500}
                  large={2500}
                />
                <ImageTitle
                  className={styles["post__image-title"]}
                  direction="horizontal"
                  text={blok.image.alt}
                />
              </div>
            </div>
          </div>
          {blok.header && blok.header.content[0].content && (
            <div className={`row row--center ${styles["post__row"]}`}>
              <div className="col col-12 text-center col-md-7">
                {/* <h3 className={`heading-4 ${styles['post__header']}`}>{blok.header}</h3> */}
                <TextRenderer
                  className={styles["post__header"]}
                  text={blok.header}
                  linkAsText
                />
              </div>
            </div>
          )}
          <div className={`row row--center ${styles["post__row"]}`}>
            <div className="col col-12 text-center col-md-7">
              <ul className={styles["post__info"]}>
                <li className={styles["post__info-item"]}>
                  <DateSVG className={styles["post__info-icon"]} />
                  <span
                    className={`link link--large light ${styles["post__info-label"]}`}
                  >
                    {date}
                  </span>
                </li>
                <li className={styles["post__info-item"]}>
                  <AuthorSVG className={styles["post__info-icon"]} />
                  <span
                    className={`link link--large light ${styles["post__info-label"]}`}
                  >
                    {blok.author}
                  </span>
                </li>
                <li className={styles["post__info-item"]}>
                  <CopyToClipboard
                    text={getBlogPostLink(slug)}
                    onCopy={() => setCopied(true)}
                  >
                    <button className={`button ${styles["post__info-copy"]}`}>
                      <LinkSVG className={styles["post__info-icon"]} />
                      <span className={copyClasses}>Copy link</span>
                    </button>
                  </CopyToClipboard>
                </li>
              </ul>
            </div>
          </div>
          <article className={`row row--center ${styles["post__row"]}`}>
            <div className="col col-12 col-md-7">
              <ShareLinks
                url={getBlogPostLink(slug)}
                title={title}
                excerpt={blok.excerpt}
              />
              <TextRenderer
                className={styles["post__content"]}
                text={blok.content}
                linkAsText
              />
            </div>
          </article>
        </div>
      </div>
      <div className={`${styles["related-posts"]}`}>
        <div className="container">
          {blok.related && blok.related.length && (
            <div className={`row row--center`}>
              <div className="col col-12 text-center col-md-9">
                <div className={styles["post__related-header"]}>
                  <h6 className="heading heading-5">
                    <span className="subtitle">Read more</span>
                  </h6>
                  <h4 className="heading heading-4">
                    <b>Dive into other publications.</b>
                  </h4>
                </div>
                <ul className={styles["post__related"]}>
                  {blok.related.map((item, i) => {
                    const postData = allPosts.nodes.find(post => {
                      return post.uuid == item
                    })

                    if (!postData) return null

                    return (
                      <li className={styles["post__related-item"]} key={i}>
                        <Thumbnail blok={postData} />
                      </li>
                    )
                  })}
                </ul>
              </div>
            </div>
          )}
        </div>
      </div>
    </SbEditable>
  )
}

export default PostArticle

import React, { forwardRef } from 'react'
import 'lazysizes'
import 'lazysizes/plugins/attrchange/ls.attrchange'

const ResponsiveImage = ({ image, mobile = 400, desktop = 1440, large = 2000, className, noLazyLoad }, ref) => {

    const resize = (image, params) => {
        
        let file = image.filename
        let imageService = 'https://img2.storyblok.com/'
        let path = file.replace('https://a.storyblok.com', '')
        let newFile = imageService + params + '/filters:format(webp)' + path
        
        return newFile
    }

    if (noLazyLoad) {
        return <img ref={ref} className={className} src={image.filename} alt={image.alt}/>
    } else {
        return (
            <img 
                ref={ref}
                data-src={resize(image, `${large}x0`)}
                data-srcset={`${resize(image, `${large}x0`)} ${large}w, ${resize(image, `${desktop}x0`)} ${desktop}w, ${resize(image, `${mobile}x0`)} ${mobile}w`}
                data-sizes={`(max-width: ${large}px) 100vw, ${large}px`}
                alt={image.alt}
                className={`lazyload img-responsive ${className}`}
            />
        )
    }

}

export default forwardRef(ResponsiveImage)

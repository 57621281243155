import React from 'react'

const Icon = ({ className }) => {
	return (
		<svg className={`icon ${className || ''}`} xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'>
			<path style={{ fill: '#fff' }} d='M18.68,0H1.41A1.37,1.37,0,0,0,0,1.4V18.5A1.4,1.4,0,0,0,1.41,20H18.58A1.37,1.37,0,0,0,20,18.6V1.4A1.24,1.24,0,0,0,18.68,0ZM7.13,15.1H4.72V7.8H7.13ZM5.93,6.8h0a1.3,1.3,0,1,1,0-2.6,1.29,1.29,0,0,1,1.4,1.3A1.35,1.35,0,0,1,5.93,6.8Zm10,8.3H13.56V11.2c0-1-.4-1.7-1.2-1.7a1.22,1.22,0,0,0-1.21.9,1.27,1.27,0,0,0-.1.6v4.1H8.44V7.8h2.41v1a2.39,2.39,0,0,1,2.21-1.2c1.61,0,2.81,1,2.81,3.3v4.2Z' />
		</svg>
	)
}

export default Icon

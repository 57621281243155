import React, { useRef, useEffect } from 'react'
import SbEditable from 'storyblok-react'
import { useInView } from 'react-intersection-observer'
import gsap from 'gsap'

import Link from './../Link'
import ButtonPrimary from './../ButtonPrimary'
import TextRenderer from './../TextRenderer'
import Socials from './../Socials'

import styles from './styles.module.scss'
import ResponsiveImage from '../ResponsiveImage'

const Footer = ({ blok, settings }) => {

	const wrapperRef = useRef(null)
	const imageRef = useRef(null)
	const headerRef = useRef(null)

	// const { innerRef, inView } = useInView()

	// let fixer = 0.004
    // let outbreak = 5

    // const mouseMoveHandler = e => {
        
    //     let pageX =  e.pageX + (wrapperRef.current.getBoundingClientRect().width)
    //     let pageY =  e.pageY + (wrapperRef.current.getBoundingClientRect().height)

    //     let image = imageRef.current
    //     let header = headerRef.current
        
    //     gsap.to(image, {
    //         x: (image.offsetLeft - pageX * outbreak) * fixer,
    //         y: (image.offsetTop - pageY * outbreak) * fixer,
    //         duration: 10,
    //         ease: 'power2.easeOut'
    //     })

    //     gsap.to(header, {
    //         x: (header.offsetLeft + pageX * (outbreak / 4)) * fixer,
    //         y: (header.offsetBottom + pageY * (outbreak / 4)) * fixer,
    //         duration: 2,
    //         ease: 'power2.easeOut'
    //     })

	// }

	// useEffect(() => {
    //     window.addEventListener('mousemove', mouseMoveHandler)
    //     return () => {
    //         window.removeEventListener('mousemove', mouseMoveHandler)
    //     }
    // }, [inView])
	
	return (
		<SbEditable content={blok} key={blok._uid}>
			<footer ref={wrapperRef} className={styles['footer']}>
				<div ref={headerRef} className={`container ${styles['footer__container']} ${styles['footer__header']}`}>
					<h4 className={`heading-2 ${styles['footer__heading']}`}><b>{blok.heading}</b></h4>
					{blok.button && blok.button.map(entry => 
						<ButtonPrimary key={entry._uid} {...entry} />
					)}
				</div>
				<div className={`container ${styles['footer__container']}`}>
					<div className='row align-items-end'>
						<div className={`col col-12 col-md-2 ${styles['footer__col']}`}>
							<h5 className={`link ${styles['footer__col-title']}`}>Alex Schoemaker <br />Living Creations B.V.</h5>
							{settings && settings.footer_address && <TextRenderer text={settings.footer_address} className={styles['footer__text']}/>}
						</div>
						<div className={`col col-12 col-md-2 ${styles['footer__col']}`}>
							<h5 className={`link ${styles['footer__col-title']}`}>Contact us</h5>
							{settings && settings.footer_contact && <TextRenderer text={settings.footer_contact} className={styles['footer__text']}/>}
						</div>
						<div className={`col col-12 col-md-3 ${styles['footer__col']}`}>
							<h5 className={`link ${styles['footer__col-title']}`}>Menu</h5>
							<ul className={styles['footer__menu']}>
								{settings && settings.footer_navi && settings.footer_navi.slice(0, 3).map(entry => {
									return (
										<li key={entry._uid}>
											<Link className={`paragraph link ${styles['footer__text']}`} to={entry.link.cached_url}>{entry.text}</Link>
										</li>
									)
								})}
							</ul>
							<ul className={styles['footer__menu']}>
								{settings && settings.footer_navi && settings.footer_navi.slice(3, settings.footer_navi.length).map(entry => {
									return (
										<li key={entry._uid}>
											<Link className={`paragraph link ${styles['footer__text']}`} to={entry.link.cached_url}>{entry.text}</Link>
										</li>
									)
								})}
							</ul>
						</div>
						<div className={`col col-12 col-md-5 ${styles['footer__copyright']}`}>
							{settings && settings.socials && <Socials data={settings.socials} className={styles['footer__socials']}/>}
							<p className={styles['footer__text']}>© Alex Schoemaker {new Date().getFullYear()}. All Rights Reserved.</p>
						</div>
					</div>
				</div>
				{blok.image.filename && (
					<figure className={styles['footer__image-wrapper']}>
						<ResponsiveImage ref={imageRef} className={styles['footer__image']} image={blok.image} mobile={400} desktop={1280} large={2000} />
					</figure>
				)}
			</footer>
		</SbEditable>
	)
}

export default Footer

import React from 'react'

import styles from './styles.module.scss'

const Logo = () => {
    return (
        <svg className={`img-hover ${styles['logo']}`} xmlns='http://www.w3.org/2000/svg' width='1105' height='175.75' viewBox='0 0 1105 175.75'>
            <path className={styles['logo__path']} d='M14.11,49.4,8.41,65.47H0L23.89,1.26h9l23.8,64.21H48.31L42.61,49.4ZM40,42.21,28.5,8.71h-.18L16.83,42.21Z'/>
            <path className={styles['logo__path']} d='M89.93,1.26v57h33.93v7.18H81.79V1.26Z'/>
            <path className={styles['logo__path']} d='M191.36,28.65v7.18H158.15V58.29h38.64v7.18H150V1.26H195.7V8.44H158.15V28.65Z'/>
            <path className={styles['logo__path']} d='M221.13,1.26h9L246.1,26.94h.09l16-25.68h9.05L250.71,32.51l22.08,33h-10l-16.64-27h-.09l-16.56,27h-9.95l22.07-32.78Z'/>
            <path className={styles['logo__path']} d='M381.72,48.68c0,9.34-7,18.05-23.34,18.05-15.29,0-27.14-8.08-28.05-22.81h8.15c.9,8.8,6.33,15.62,19.9,15.62,11.22,0,15.2-5.11,15.2-10.86,0-8.8-4.8-10.42-19.27-13.74-9.68-2.25-21.26-5.57-21.26-18.06C333.05,6.74,340.92,0,355.21,0,369,0,378.56,7,379.64,20.3H371.5c-1.18-8.62-6.06-13.12-16.29-13.12-9.14,0-14,3.51-14,8.81,0,8.53,7,9.7,16.47,12C372.22,31.61,381.72,34,381.72,48.68Z'/>
            <path className={styles['logo__path']} d='M436.64,7.18c-15.38,0-21.8,13.21-21.8,26.14s6.42,26.22,21.8,26.22c13.57,0,18.82-9.16,19.36-17.24h8.15c-1.45,14.19-11.58,24.43-27.51,24.43-17.73,0-29.94-14-29.94-33.41S418.91,0,436.64,0c15.84,0,26,10.24,27.51,22.45H456C454.83,16.35,450.12,7.18,436.64,7.18Z'/>
            <path className={styles['logo__path']} d='M499.53,1.26v27.3h34.56V1.26h8.14V65.47h-8.14V35.74H499.53V65.47h-8.15V1.26Z'/>
            <path className={styles['logo__path']} d='M570.46,33.32C570.46,14,583.13,0,602.13,0s31.66,14,31.66,33.32-12.66,33.41-31.66,33.41S570.46,52.63,570.46,33.32Zm55.19,0c0-13.83-7.24-26.14-23.52-26.14S578.6,19.49,578.6,33.32s7.24,26.22,23.53,26.22S625.65,47.15,625.65,33.32Z'/>
            <path className={styles['logo__path']} d='M702.92,28.65v7.18H669.71V58.29h38.64v7.18H661.57V1.26h45.69V8.44H669.71V28.65Z'/>
            <path className={styles['logo__path']} d='M735.94,1.26h11.13L767.34,54.6h.18L787.79,1.26h11.12V65.47h-8.14V12.66h-.18L771.05,65.47h-7.24L744.27,12.66h-.18V65.47h-8.15Z'/>
            <path className={styles['logo__path']} d='M838.09,49.4l-5.7,16.07H824L847.86,1.26h9l23.8,64.21h-8.42l-5.7-16.07ZM864,42.21,852.48,8.71h-.18l-11.49,33.5Z'/>
            <path className={styles['logo__path']} d='M913.91,1.26V35.65L946.39,1.26h10L930.92,27.93l27.23,37.54h-9.5l-23.16-32L913.91,45.62V65.47h-8.14V1.26Z'/>
            <path className={styles['logo__path']} d='M1023.48,28.65v7.18h-33.2V58.29h38.63v7.18H982.13V1.26h45.69V8.44H990.28V28.65Z'/>
            <path className={styles['logo__path']} d='M1056.51,1.26h26.23c12.76,0,21.36,6.82,21.36,18.23,0,9.25-4.44,14.37-11.58,16.16v.18c14.38,2.79,9.13,28,12.48,29.1v.54h-8.78c-2.8-2.69,3.35-25.95-13-25.95h-18.55V65.47h-8.14Zm8.14,31.07h16.19c6.7,0,15.11-.81,15.11-11.94s-8.41-12-15.11-12h-16.19Z'/>
            <path className={styles['logo__path'], styles['logo__path--light']} d='M170,127.35v42.16h25.08v5.31H164V127.35Z'/>
            <path className={styles['logo__path'], styles['logo__path--light']} d='M220.55,127.35v47.47h-6V127.35Z'/>
            <path className={styles['logo__path'], styles['logo__path--light']} d='M245.56,127.35l13.64,41.49h.07l13.64-41.49h6.35l-16.72,47.47h-6.62L239.2,127.35Z'/>
            <path className={styles['logo__path'], styles['logo__path--light']} d='M303.94,127.35v47.47h-6V127.35Z'/>
            <path className={styles['logo__path'], styles['logo__path--light']} d='M326.4,127.35h7.36l23.88,39.1h.13v-39.1h6v47.47h-7.36l-23.87-39h-.14v39h-6Z'/>
            <path className={styles['logo__path'], styles['logo__path--light']} d='M406.85,131.73c-11.37,0-16.18,10.43-16.18,19.32s3.48,19.39,15.51,19.39c8.56,0,15.59-6.11,15.59-15H406.18v-5.31h21.6v24.7h-4l-2-6.71h-.14c-3.61,5-8.09,7.64-15.45,7.64-13.91,0-21.53-10.36-21.53-24.7s9-24.63,22.2-24.63c12.11,0,18.46,6.24,20.13,15.27h-6C419.56,134.92,414.07,131.73,406.85,131.73Z'/>
            <path className={styles['logo__path'], styles['logo__path--light']} d='M496.53,131.73c-11.37,0-16.12,9.76-16.12,19.32s4.75,19.39,16.12,19.39c10,0,13.91-6.77,14.31-12.75h6c-1.07,10.49-8.56,18.06-20.33,18.06-13.11,0-22.14-10.36-22.14-24.7s9-24.63,22.14-24.63c11.7,0,19.19,7.57,20.33,16.6h-6C510,138.5,506.49,131.73,496.53,131.73Z'/>
            <path className={styles['logo__path'], styles['logo__path--light']} d='M537,127.35h19.4c9.43,0,15.78,5.05,15.78,13.48,0,6.84-3.28,10.62-8.56,11.95v.13c10.63,2.06,6.75,20.71,9.23,21.51v.4h-6.49c-2.07-2,2.48-19.19-9.63-19.19H543v19.19h-6Zm6,23h12c4.95,0,11.17-.6,11.17-8.83s-6.22-8.83-11.17-8.83H543Z'/>
            <path className={styles['logo__path'], styles['logo__path--light']} d='M623.25,147.6v5.31H598.7v16.6h28.56v5.31H592.69V127.35h33.77v5.31H598.7V147.6Z'/>
            <path className={styles['logo__path'], styles['logo__path--light']} d='M654.94,162.93l-4.21,11.89h-6.22l17.66-47.47h6.68l17.59,47.47h-6.22L676,162.93Zm19.13-5.31-8.49-24.76h-.14L657,157.62Z'/>
            <path className={styles['logo__path'], styles['logo__path--light']} d='M711.85,132.66H695.93v-5.31h37.86v5.31H717.87v42.16h-6Z'/>
            <path className={styles['logo__path'], styles['logo__path--light']} d='M759.26,127.35v47.47h-6V127.35Z'/>
            <path className={styles['logo__path'], styles['logo__path--light']} d='M780.26,151.05c0-14.27,9.36-24.63,23.41-24.63s23.4,10.36,23.4,24.63-9.36,24.7-23.4,24.7S780.26,165.33,780.26,151.05Zm40.79,0c0-10.22-5.35-19.32-17.38-19.32s-17.39,9.1-17.39,19.32,5.35,19.39,17.39,19.39S821.05,161.28,821.05,151.05Z'/>
            <path className={styles['logo__path'], styles['logo__path--light']} d='M847.6,127.35H855l23.87,39.1H879v-39.1h6v47.47h-7.35l-23.88-39h-.13v39h-6Z'/>
            <path className={styles['logo__path'], styles['logo__path--light']} d='M942.29,162.4c0,6.91-5.15,13.35-17.25,13.35-11.31,0-20.07-6-20.74-16.86h6c.67,6.5,4.69,11.55,14.72,11.55,8.29,0,11.23-3.79,11.23-8,0-6.5-3.54-7.7-14.24-10.15-7.16-1.66-15.72-4.12-15.72-13.35,0-7.5,5.82-12.48,16.39-12.48,10.16,0,17.25,5.18,18,15h-6c-.86-6.38-4.48-9.7-12-9.7-6.76,0-10.37,2.59-10.37,6.51,0,6.31,5.15,7.17,12.17,8.9C935.27,149.79,942.29,151.58,942.29,162.4Z'/>
            <path className={styles['logo__path'], styles['logo__path--light']} d='M953.45,136a9.28,9.28,0,1,1,9.28,9.37A9.16,9.16,0,0,1,953.45,136Zm17,0a7.72,7.72,0,1,0-7.72,7.82A7.65,7.65,0,0,0,970.45,136Zm-11.15-5.07h4.06c2,0,3.31,1.08,3.31,2.83a2.31,2.31,0,0,1-1.79,2.51v0c2.22.42,1.4,4.33,1.91,4.49v.08H965c-.42-.43.55-4-2.37-4h-1.72v4H959.3Zm1.64,4.45h1.72c1.05,0,2.34,0,2.34-1.47s-1.29-1.47-2.34-1.47h-1.72Z'/>
        </svg>
    )
}

export default Logo
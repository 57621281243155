import React from 'react'

import Mail from './Mail'
import LinkedIn from './LinkedIn'
import Instagram from './Instagram'
import Arrow from './Arrow'
import Cross from './Cross'

const Components = {
	'arrow': Arrow,
    'mail': Mail,
    'linkedin': LinkedIn,
	'instagram': Instagram,
	'cross': Cross
}

const DynamicIcon = ({ type, className }) => {
	
	if (typeof Components[type] !== 'undefined') {
		const Component = Components[type]
		return <Component className={className} />
	}

	return null
}

export default DynamicIcon
import React from 'react'
import SbEditable from 'storyblok-react'
import { Link } from 'gatsby'

import styles from './styles.module.scss'

const AnchorLink = (blok) => {
    return (
        <SbEditable content={blok} key={blok._uid}>
            <Link to={`#${blok.anchor}`} className={styles['anchor_link']}>
                <span className={styles['anchor_label']}>{blok.text}</span>
                <span className={styles['anchor_arrow']}>→</span>
            </Link>
        </SbEditable>
    )
}

export default AnchorLink

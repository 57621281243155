import React from 'react'
import SbEditable from 'storyblok-react'

import styles from './styles.module.scss'

const Image = (blok) => {
    return (
        <SbEditable content={blok}>
            {blok.image && blok.image.filename ? (
                <img className={styles['image__image']} style={{ width: `${blok.size}rem` || '100%' }} src={blok.image.filename} alt={blok.image.alt} />
            ) : 'Insert image'}
        </SbEditable>
    )
}

export default Image
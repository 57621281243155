import React from 'react'
import { Link } from 'gatsby'
import { render, MARK_LINK, NODE_HEADING, NODE_UL, NODE_OL, NODE_PARAGRAPH, MARK_STYLED, NODE_IMAGE } from 'storyblok-rich-text-react-renderer'
import { getLocalLink } from './../../utils/helpers'

import ButtonPrimary from './../ButtonPrimary'
import ImageTitle from './../ImageTitle'
import Image from './../Image'
import AnchorLink from './../AnchorLink'

const TextRenderer = ({ text, className, linkAsText }) => {
    return (
        <>
            {render(text, {
                nodeResolvers: {
                    [NODE_HEADING]: (children, props) => {
                        switch (props.level) {
                            case 1: return <h1 className={`heading heading-${props.level} ${className || ''}`}>{children}</h1>
                            case 2: return <h2 className={`heading heading-${props.level} ${className || ''}`}>{children}</h2>
                            case 3: return <h3 className={`heading heading-${props.level} ${className || ''}`}>{children}</h3>
                            case 4: return <h4 className={`heading heading-${props.level} ${className || ''}`}>{children}</h4>
                            case 5: return <h5 className={`heading heading-${props.level} ${className || ''}`}>{children}</h5>
                            case 6: return <h6 className={`heading heading-${props.level} ${className || ''}`}>{children}</h6>
                        }
                    },
                    [NODE_PARAGRAPH]: (children) => {
                        return (
                            <div className={`paragraph ${className || ''}`}>{children}</div>
                        )
                    },
                    [NODE_UL]: (children) => {
                        return (
                            <ul className={`list list--unordered ${className || ''}`}>{children}</ul>
                        )
                    },
                    [NODE_OL]: (children) => {
                        return (
                            <ul className={`list list--ordered ${className || ''}`}>{children}</ul>
                        )
                    },
                    [NODE_IMAGE]: (children, { src, alt, title }) => {
                        return (
                            <div className='text-renderer__image-wrapper'>
                                <img className='text-renderer__image' src={src} />
                                <ImageTitle className='text-renderer__image-title' direction='horizontal' text={alt || title} />
                            </div>
                        )
                    }
                },
                markResolvers: {
                    [MARK_STYLED]: (children, props) => {
                        return (
                            <span className={props.class}>{children}</span>
                        )
                    },
                    [MARK_LINK]: (children, props) => {
                        if (props.linktype == 'story') {
                            return <Link className={`link ${linkAsText && 'link--as-text'} ${className || ''}`} to={getLocalLink(props.href)} >{children}</Link>
                        }
                        return (
                            <a className={`link ${linkAsText && 'link--as-text'} ${className || ''}`} href={props.linktype == 'email' ? `mailto:${props.href}` : props.href} target={props.target || null}>{children}</a>
                        )
                    }
                },
                blokResolvers: {
                    ['button']: (props) => <ButtonPrimary {...props} />,
                    ['image']: (props) => <Image {...props} />,
                    ['anchor_link']: (props) => <AnchorLink {...props} />
                }
            })}
        </>
    )
}

export default TextRenderer
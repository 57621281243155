import React, { useRef, useState, useEffect, useContext } from 'react'
import cx from 'classnames'
import gsap from 'gsap'

import Link from './../Link'
import styles from './styles.module.scss'
import ResponsiveImage from '../ResponsiveImage'
import LanguageSwitch from '../LanguageSwitch'

const Menu = ({ menuOpen, settings }) => {

    let body = typeof window !== 'undefined' && document.body
    
    const [animateIn, setAnimateIn] = useState(null)
    const [animateOut, setAnimateOut] = useState(null)

    const containerRef = useRef(null)
    const imageWrapperRef = useRef(null)
    const imageRef = useRef(null)
    const menuRef = useRef(null)
    
    const menuClasses = cx({
        [styles['menu']]: true,
        [styles['menu--open']]: menuOpen
    })

    const setStyles = () => {
        gsap.set(containerRef.current, { x: '100%', alpha: 1.0 })
        gsap.set(imageWrapperRef.current, { alpha: 0.0, x: -15.0 })
        gsap.set(menuRef.current, { alpha: 0.0, x: -15.0 })
    }

    useEffect(() => {

        setStyles()

        let tl = new gsap.timeline({ paused: true })
        tl.to(containerRef.current, { x: 0.0, duration: 0.35, ease: 'expo.easeOut' })
        tl.to(imageWrapperRef.current, { alpha: 1.0, x: 0.0, duration: 0.5, ease: 'expo.easeOut' }, 0.35)
        tl.to(menuRef.current, { alpha: 1.0, x: 0.0, duration: 0.5, ease: 'expo.easeOut' }, 0.45)

        setAnimateIn(tl)
        
        return () => {
            animateIn && animateIn.kill()
        }
    }, [])

    useEffect(() => {
        
        let tl = new gsap.timeline({ paused: true })
        tl.to(containerRef.current, { alpha: 0.0, duration: 0.35, ease: 'expo.easeIn' }, 0.0)

        setAnimateOut(tl)
        
        return () => {
            animateOut && animateOut.kill()
        }
    }, [])

    useEffect(() => {

        if (menuOpen == null) return

        if (menuOpen) {
            body.classList.add('no-scroll')
            animateIn.restart()
        } else {
            body.classList.remove('no-scroll')
            animateOut.eventCallback('onComplete', () => setStyles()).restart()
        }
        
    }, [menuOpen])

    return (
        <aside ref={containerRef} className={menuClasses}>
            <figure ref={imageWrapperRef} className={styles['menu__image-wrapper']}>
                {settings && settings.menu_images && <ResponsiveImage ref={imageRef} className={styles['menu__image']} image={settings.menu_images[0]} desktop={600} large={1280} />}
            </figure>
            <div ref={menuRef} className={styles['menu__content-wrapper']}>
                <div className={styles['menu__content-inner']}>
                    {/* <span className={`link link--medium ${styles['menu__subtitle']}`}>Menu</span> */}
                    <nav className={styles['menu__main-nav']}>
                        <ul className={styles['menu__main-nav-list']}>
                            {settings && settings.main_navi && settings.main_navi.map(entry => {
                                return (
                                    <li className={styles['menu__nav-item']} key={entry._uid}>
                                        <Link onClick={() => setMenuOpen(!menuOpen)} activeStyle={{ opacity: 1 }} className={`link link--extra-large link--menu`} to={entry.link.cached_url} anchor={entry.link.anchor}>{entry.text == 'Nursery news' ? 'News' : entry.text}</Link>
                                    </li>
                                )
                            })}
                        </ul>
                    </nav>
                    {settings && settings.sub_navi && settings.sub_navi.length > 0 && <nav className={styles['menu__sub-nav']}>
                        <ul className={styles['menu__main-nav-list']}>
                            {settings && settings.sub_navi && settings.sub_navi.map(entry => {
                                return (
                                    <li className={styles['menu__nav-item']} key={entry._uid}>
                                        <Link onClick={() => setMenuOpen(!menuOpen)} activeStyle={{ opacity: 1 }} className={`link link--large link--menu`} to={entry.link.cached_url}>{entry.text}</Link>
                                    </li>
                                )
                            })}
                        </ul>
                    </nav>}
                    <div className={styles['menu__footer']}>
                        <div className={styles['menu__contact-info']}>
                            <nav className={styles['menu__contact-nav']}>
                                {/* <span className={`link ${styles['menu__subtitle']}`}>Contact</span> */}
                                <ul className={styles['menu__contact-info-list']}>
                                    {settings && settings.contact && settings.contact.map(entry => {
                                        return (
                                            <li className={styles['menu__nav-item']} key={entry._uid}>
                                                <Link onClick={() => setMenuOpen(!menuOpen)} activeStyle={{ opacity: 1 }} className={`link`} to={entry.link.email || entry.link.url}>{entry.text}</Link>
                                            </li>
                                        )
                                    })}
                                </ul>
                            </nav>
                        </div>
                        <div className={`container container--large ${styles['menu__footer-inner']}`}>
                            <span className={`link link--medium ${styles['menu__copyright']}`}>© Alex Schoemaker {new Date().getFullYear()}. All Rights Reserved.</span>
                            <LanguageSwitch className={styles['menu__language-switch']}/>
                        </div>
                    </div>
                </div>
            </div>
        </aside>
    )
}

export default Menu
import React, { useEffect, useRef, useState } from 'react'
import cx from 'classnames'
import gsap from 'gsap'

import styles from './styles.module.scss'

const ButtonIcon = ({ menuOpen }) => {

    const svgRef = useRef(null)
    const lineRefs = useRef([])

    const [timeline, setTimeline] = useState(null)

    useEffect(() => {

        let offset = (svgRef.current.clientHeight / 2) - 0.5
        let duration = 0.2
        
        const tl = new gsap.timeline({ paused: true })
        tl.to(lineRefs.current[0], { y: -offset, duration: duration, ease: 'power3.easeIn' }, 0.0)
        tl.to(lineRefs.current[2], { y: offset, duration: duration, ease: 'power3.easeIn' }, 0.0)
        tl.to(lineRefs.current[0], { rotate: 45.0, duration: duration, ease: 'power3.easeIn' }, 0.0)
        tl.to(lineRefs.current[2], { rotate: -45.0, duration: duration, ease: 'power3.easeIn' }, 0.0)

        setTimeline(tl)
        
        return () => {
            timeline && timeline.kill()
        }
    }, [])

    useEffect(() => {
        
        if (menuOpen == null) return

        menuOpen ? timeline.play() : timeline.reverse()

    }, [menuOpen])

    return (
        <svg ref={svgRef} className={styles['button-menu__icon']} xmlns='http://www.w3.org/2000/svg' width='17' height='13' viewBox='0 0 17 13'>
            <line ref={el => lineRefs.current[0] = el} className={styles['button-menu__icon-line']} x1='0.5' y1='12.5' x2='16.5' y2='12.5'/>
            <line ref={el => lineRefs.current[1] = el} className={styles['button-menu__icon-line']} x1='0.5' y1='6.5' x2='16.5' y2='6.5'/>
            <line ref={el => lineRefs.current[2] = el} className={styles['button-menu__icon-line']} x1='0.5' y1='0.5' x2='16.5' y2='0.5'/>
        </svg>
    )
}

const ButtonMenu = ({ onClick, menuOpen }) => {
    
    const buttonClasses = cx({
        'button': true,
        [styles['button-menu']]: true,
        [styles['button-menu--open']]: menuOpen
    })

    return (
        <button className={buttonClasses} onClick={onClick}>
            <ButtonIcon menuOpen={menuOpen} />
            <div className={styles['button-menu__inner']}>
                <span className={`link ${styles['button-menu__label']}`}>{menuOpen ? 'Close' : 'Menu'}</span>
            </div>
        </button>
    )
}

export default ButtonMenu

import React, { useRef, useState, useEffect } from 'react'
import cx from 'classnames'
import gsap from 'gsap'

import Link from './../Link'
import Logo from './../Logo'
import ButtonMenu from './../ButtonMenu'
import Menu from './../Menu'

import useScrollDirection from './../../utils/useScrollDirection'

import styles from './styles.module.scss'

const Header = ({ settings }) => {
	
	const [menuOpen, setMenuOpen] = useState(null)
	const [atTop, setAtTop] = useState(true)
	
	const navRef = useRef(null)

	const scrollDirection = useScrollDirection()

	const headerClasses = cx({
        [styles['header']]: true,
        [styles['header--sticky']]: !atTop,
        [styles['header--hide']]: scrollDirection == 'down' && !atTop,
        [styles['header--show']]: scrollDirection == 'up' || atTop
    })

	const scrollHandler = ({ element, useWindow }) => {
		
		const target = element ? element.current : document.body
        const position = target.getBoundingClientRect()
        const scrollPos = useWindow ? { 
			x: window.scrollX, 
			y: window.scrollY 
		} : { 
			x: position.left, 
			y: position.top 
		}

        if (scrollPos.y < 0) {
            setAtTop(false)
        } else if (scrollPos.y == 0) {
            setAtTop(true)
		}

	}
    
    useEffect(() => {
        window.addEventListener('scroll', scrollHandler)
        
        return () => {
            window.removeEventListener('scroll', scrollHandler)
        }
	}, [])

	useEffect(() => {

		if (menuOpen) {
			gsap.to(navRef.current, { alpha: 0.0, y: -15.0, duration: 0.15, ease: 'expo.easOut' })
		} else {
			gsap.to(navRef.current, { alpha: 1.0, y: 0.0, duration: 0.15, ease: 'expo.easIn' })
		}
		
	}, [menuOpen])

	return (
		<header className={headerClasses}>
			<div className={`container container--large ${styles['header__inner']}`}>
				<div className={`row align-items-center ${styles['header__row']}`}>
					<div className={`col col-6 col-md-3 ${styles['header__logo-col']}`}>
						<Link to='en/' className={styles['header__logo-wrapper']}>
							<Logo />
						</Link>
					</div>
					<div className={`col col-12 col-md-6 ${styles['header__short-nav-col']}`}>
						<nav ref={navRef}>
							<ul className={styles['header__short-nav']}>
								{settings && settings.header_navi && settings.header_navi.map((entry, i) => 
									<li key={entry._uid} className={styles['header__short-nav-item']}>
										<Link activeStyle={{ opacity: 1 }} className='link' to={entry.link.cached_url}>{entry.text}</Link>
									</li>
								)}
							</ul>
						</nav>
					</div>
					<div className={`col col-6 col-md-3 ${styles['header__button-col']}`}>
						<ButtonMenu onClick={() => setMenuOpen(!menuOpen)} menuOpen={menuOpen} />
					</div>
				</div>
			</div>
			<Menu menuOpen={menuOpen} settings={settings} />
		</header>
	)
}

export default Header

import React from 'react'

const Icon = ({ className }) => {
    return (
        <svg className={`icon ${className || ''}`} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 15.6 15.6'>
            <path d='M0.7,0.7l14.2,14.2' />
            <path d='M0.7,14.9L14.9,0.7' />
        </svg>
    )
}

export default Icon
import React from 'react'

const Icon = ({ className }) => {
	return (
		<svg className={`icon ${className || ''}`} xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'>
			<path style={{ fill: '#fff' }} d='M14.48,0h-9A5.53,5.53,0,0,0,0,5.52v9A5.53,5.53,0,0,0,5.52,20h9A5.53,5.53,0,0,0,20,14.48v-9A5.53,5.53,0,0,0,14.48,0Zm3.75,14.48a3.75,3.75,0,0,1-3.75,3.75h-9a3.75,3.75,0,0,1-3.75-3.75v-9A3.75,3.75,0,0,1,5.52,1.77h9a3.75,3.75,0,0,1,3.75,3.75v9Z'/>
			<path style={{ fill: '#fff' }} d='M10,5a5,5,0,1,0,5,5A5,5,0,0,0,10,5Zm0,8.28A3.28,3.28,0,1,1,13.28,10,3.28,3.28,0,0,1,10,13.28Z'/>
			<path style={{ fill: '#fff' }} d='M15.5,3a1.52,1.52,0,0,0-1.06.44A1.5,1.5,0,0,0,14,4.5,1.5,1.5,0,0,0,15.5,6a1.5,1.5,0,0,0,1.06-.44A1.52,1.52,0,0,0,17,4.5,1.5,1.5,0,0,0,15.5,3Z'
			/>
		</svg>
	)
}

export default Icon
import React from 'react'

const Icon = () => {
	return (
		<svg className={`icon ${className || ''}`} xmlns='http://www.w3.org/2000/svg' width='63' height='61'>
			<g fill='none' fillRule='evenodd'>
				<path fill='#FFF' d='M-1074-65H266v372h-1340z'></path>
					<path
						fill='#672E9B'
						d='M34.71 61c16.419 0 25.002-15.139 27.95-30.884C65.607 14.371 48.884 4.042 32.93.481 16.98-3.079 0 13.75 0 30.116S18.292 61 34.71 61z'
					></path>
				<g
					stroke='#FFF'
					strokeLinecap='round'
					strokeLinejoin='round'
					strokeWidth='2'
				>
					<path d='M21.5 20h20c1.375 0 2.5 1.181 2.5 2.625v15.75C44 39.819 42.875 41 41.5 41h-20c-1.375 0-2.5-1.181-2.5-2.625v-15.75C19 21.181 20.125 20 21.5 20z'></path>
					<path d='M44 22.8l-12.5 8.4L19 22.8'></path>
				</g>
			</g>
		</svg>
	)
}

export default Icon
